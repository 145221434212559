import React from 'react'

import shotJavaFtsDevoxx from '../assets/images/java/shot-java-fts-devoxx.png'
import shotJavaReactive from '../assets/images/java/shot-java-reactive.png'
import shotJavaFtsTraining from '../assets/images/java/shot-java-training.png'

const Java = () => {
  return (
    <>
      <div className="page--home m-8">
        <h1 className="md:text-3xl text-2xl font-semibold mb-3">Couchbase and Java</h1>
        <hr />
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 p-0 lg:pt-5 mb-5">
          <div className="rounded overflow-hidden shadow-lg mx-0 lg:mr-6 md:my-6 my-5 bg-gray-100">
            <div className="px-6 py-2">
              <div className="flex lg:justify-center flex-wrap lg:flex-no-wrap lg:px-6 py-2">
                <a className="flex items-center hover:no-underline hover:text-gray-800 mb-3 text-center md:mb-1 hover:bg-gray-500 bg-gray-300 rounded-full px-3 py-1 text-sm font-semibold mr-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.couchbase.com/java-sdk/current/hello-world/start-using-sdk.html">
                  <span>Docs</span>
                </a>
                <a className="flex items-center hover:no-underline hover:text-gray-800 mb-3 text-center md:mb-1 hover:bg-gray-500 bg-gray-300 rounded-full px-3 py-1 text-sm font-semibold mr-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.couchbase.com/java-sdk/current/project-docs/sdk-release-notes.html">
                  <span>Release Notes</span>
                </a>
                <a className="flex items-center hover:no-underline hover:text-gray-800 mb-3 text-center md:mb-1 hover:bg-gray-500 bg-gray-300 rounded-full px-3 py-1 text-sm font-semibold mr-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://issues.couchbase.com/projects/JCBC/issues">
                  <span>Report Issues</span>
                </a>
                <a className="flex items-center hover:no-underline hover:text-gray-800 mb-3 text-center md:mb-1 hover:bg-gray-500 bg-gray-300 rounded-full px-3 py-1 text-sm font-semibold"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://forums.couchbase.com/c/java-sdk/">
                  <span>Ask a Question</span>
                </a>
              </div>
              <div className="font-bold text-xl mb-3 mt-3">
                The Java SDK
              </div>
              <p className="text-gray-700 text-base font-light mb-6">
                The Couchbase Java SDK is the default choice for languages running on the JVM. The SDK 3.x version is a complete rewrite of the 2.x API, providing a simpler surface area and adding support for future Couchbase Server features like <a className="blue-link" href="https://docs.couchbase.com/java-sdk/current/concept-docs/collections.html">Collections and Scopes</a>.
              </p>
              <p className="text-gray-700 text-base font-light mb-6">
                The (reactive) API also migrated from <strong>RxJava</strong> to <strong>Reactor</strong>, along with other improvements to performance, logging, debugging and timeout troubleshooting. If you’re upgrading your application from Java SDK 2.x, please read our <a className="blue-link" href="https://docs.couchbase.com/java-sdk/current/project-docs/migrating-sdk-code-to-3.n.html">Migrating 2.x code to SDK 3.x Guide.</a>
              </p>
              <div className="flex mt-4 mb-2 justify-center">
                <a target="_blank"
                  style={{ color: '#fff' }}
                  rel="noopener noreferrer"
                  className="btn-gradient-default hover:bg-red-700 bg-red-600 visited:text-red-600 text-white font-norma px-4 py-2 mb-2 rounded hover:no-underline"
                  href="https://docs.couchbase.com/java-sdk/current/hello-world/start-using-sdk.html">Get Started</a>
              </div>
            </div>
          </div>
          <div className="rounded overflow-hidden shadow-lg mx-0 lg:ml-6  md:my-6 my-5 bg-gray-100">
            <div className="px-6 py-2">
              <div className="flex lg:justify-center flex-wrap lg:flex-no-wrap lg:px-6 py-2">

                <a className="flex items-center hover:no-underline hover:text-gray-800 mb-3 text-center md:mb-1 hover:bg-gray-500 bg-gray-300 rounded-full px-3 py-1 text-sm font-semibold mr-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.spring.io/spring-data/couchbase/docs/current/reference/html/#reference">
                  <span>Docs</span>
                </a>
                <a className="flex items-center hover:no-underline hover:text-gray-800 mb-3 text-center md:mb-1 hover:bg-gray-500 bg-gray-300 rounded-full px-3 py-1 text-sm font-semibold mr-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.spring.io/spring-data/couchbase/docs/current/changelog.txt">
                  <span>Release Notes</span>
                </a>
                <a className="flex items-center hover:no-underline hover:text-gray-800 mb-3 text-center md:mb-1 hover:bg-gray-500 bg-gray-300 rounded-full px-3 py-1 text-sm font-semibold mr-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://jira.spring.io/projects/DATACOUCH/">
                  <span>Report Issues</span>
                </a>
                <a className="flex items-center hover:no-underline hover:text-gray-800 mb-3 text-center md:mb-1 hover:bg-gray-500 bg-gray-300 rounded-full px-3 py-1 text-sm font-semibold"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://forums.couchbase.com/c/java-sdk/">
                  <span>Ask a Question</span>
                </a>
              </div>
              <div className="font-bold text-xl mb-3 mt-3">
                The Spring Data Couchbase SDK
              </div>
              <p className="text-gray-700 text-base font-light">
                Spring Data for Couchbase is part of the umbrella
                <a className="blue-link" rel="noopener noreferrer" href="https://spring.io/projects/spring-data"> Spring Data project</a>
                which aims to provide a familiar and consistent Spring-based programming model for new datastores while retaining store-specific features and capabilities.
                <br /><br />
                The Spring Data Couchbase project provides integration with the Couchbase Server database. Key functional areas of Spring Data Couchbase are a POJO centric model for interacting with Couchbase Buckets and easily writing a Repository style data access layer.</p>
              <br />
              <div className="flex mt-4 mb-2 justify-center">
                <a target="_blank" style={{ color: '#fff' }}
                  rel="noopener noreferrer"
                  className="btn-gradient-default hover:bg-red-700 bg-red-600 visited:text-red-600 text-white font-norma px-4 py-2 mb-2 rounded hover:no-underline"
                  href="https://docs.spring.io/spring-data/couchbase/docs/current/reference/html/#installation">Get Started</a>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="bg-gray-800 rounded lg:pb-10 md:pb-6 pb-5 pt-5 px-4 ">
            <h2 className="text-white text-2xl lg:text-2xl lg:ml-3 ml-1 mb-3 ">Featured Content</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
              <div className="md:max-w-xl rounded-lg overflow-hidden shadow-lg bg-white md:m-3 mb-5">
                <div className="flex flex-col min-h-full">
                  <div className="px-6 py-4 border-b">
                    <div className="text-xl text-center text-red-600">
                      Try Couchbase - Java
                    </div>
                  </div>
                  <div className="px-6 py-5 flex-grow">
                    <p className="text-gray-700 text-base font-light">
                      This is a sample application for getting started with Couchbase Server 6.5. The application runs a single page UI and demonstrates SQL for Documents (N1QL) and Full Text Search (FTS) querying capabilities. It uses Couchbase Server 6.5.0 together with Spring Boot, Vue and Bootstrap.
                    </p>
                  </div>
                  <div className="px-5 py-3 flex justify-center">
                    <a target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#e53e3e' }}
                      className="btn-gradient-default hover:bg-red-100 border border-red-400 visited:text-red-600 text-red-600 hover:no-underline font-medium text-sm py-3 px-5 rounded mr-3 mb-2"
                      href="https://github.com/couchbaselabs/try-cb-java">View on GitHub</a>
                  </div>
                </div>
              </div>
              <div className="md:max-w-xl rounded-lg overflow-hidden shadow-lg bg-white md:m-3 mb-5">
                <div className="flex flex-col min-h-full">
                  <div className="px-6 py-4 border-b">
                    <div className="text-xl text-center text-red-600">
                      Reactive Programming
                    </div>
                  </div>
                  <div className="px-6 py-5 flex-grow">
                    <p className="text-gray-700 text-base font-light">
                      Reactor - You want to consider an asynchronous, reactive API if the blocking API does not suit your needs. Using the reactive API over the `CompletableFuture` counterpart provides everything to build scalable asynchronous stacks.
                    </p>
                  </div>
                  <div className="px-5 py-3 flex justify-center">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#e53e3e' }}
                      className="btn-gradient-default hover:bg-red-100 border border-red-400 visited:text-red-600 text-red-600 hover:no-underline font-medium text-sm py-3 px-5 rounded mr-3 mb-2"
                      href="https://docs.couchbase.com/java-sdk/current/howtos/concurrent-async-apis.html">Read More</a>
                  </div>
                </div>
              </div>
              <div className="md:max-w-xl rounded-lg overflow-hidden shadow-lg bg-white md:m-3 mb-5">
                <div className="flex flex-col min-h-full">
                  <div className="px-6 py-4 border-b">
                    <div className="text-xl text-center text-red-600">
                      Couchbase Lite - Java
                    </div>
                  </div>
                  <div className="px-6 py-5 flex-grow">
                    <p className="text-gray-700 text-base font-light">
                      You can also use
                      <a className="blue-link"
                        rel="noopener noreferrer"
                        href="https://docs.couchbase.com/couchbase-lite/current/introduction.html"> Couchbase Lite </a>
                      with Java. It is a very good fit for offline-first desktop or standalone applications enabling development and deployment of
                      Couchbase Lite applications to a JVM environment or a desktop or web server, including embedded Tomcat deployments.
                    </p>
                  </div>
                  <div className="px-5 py-3 flex justify-center">
                    <a target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#e53e3e' }}
                      className="btn-gradient-default hover:bg-red-100 border border-red-400 visited:text-red-600 text-red-600 hover:no-underline font-medium text-sm py-3 px-5 rounded mr-3 mb-2"
                      href="https://docs.couchbase.com/couchbase-lite/current/java/quickstart.html">Read More</a>
                  </div>
                </div>
              </div>
              <div className="md:max-w-xl rounded-lg overflow-hidden shadow-lg bg-white md:m-3 mb-3">
                <div className="flex flex-col min-h-full">
                  <div className="px-6 py-4 border-b">
                    <div className="text-xl text-center text-red-600">Couchflix</div>
                  </div>
                  <div className="px-6 py-5 flex-grow">
                    <p className="text-gray-700 text-base font-light">
                      A Java and JavaScript based Sample project showing how to build a search feature using Bleve and Couchbase Full Text Search. Learn how to utilize Couchbase and various FTS features to build your own Netflix like search feature.
                    </p>
                  </div>
                  <div className="px-5 py-3 flex justify-center">
                    <a target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#e53e3e' }}
                      className="btn-gradient-default hover:bg-red-100 border border-red-400 visited:text-red-600 text-red-600 hover:no-underline font-medium text-sm py-3 px-5 rounded mr-3 mb-2"
                      href="https://github.com/deniswsrosa/couchflix">View on GitHub</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />

        <div className="lg:px-0 md:px-2 px-0">
          <h2 className="text-2xl">Community Projects and Frameworks</h2>
          <p className="text-gray-500"><small>List of projects developed/maintained by the community.</small></p>
          <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 lg:mx-2 my-5">
            <div className="rounded border overflow-hidden shadow-md hover:shadow-2xl md:m-2 mb-4">
              <div className="flex flex-col min-h-full">
                <div className="px-6 py-4 border-b">
                  <div className="text-red-600 text-xl text-center">
                    Distributed ACID Transactions SDK
                  </div>
                </div>
                <div className="px-6 py-4 flex-grow">
                  <p className="text-gray-700 text-base font-light">
                    This library allows you to execute multi-document distributed ACID transactions on the top of Couchbase
                  </p>
                </div>
                <div className="px-6 pb-4 flex justify-center">
                  <a target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#fff' }}
                    className="btn-gradient-default hover:bg-red-700 bg-red-600 visited:text-red-600 text-white font-norma px-4 py-2 mb-2 rounded hover:no-underline"
                    href="https://docs.couchbase.com/java-sdk/current/howtos/distributed-acid-transactions-from-the-sdk.html">Read More</a>
                </div>
              </div>
            </div>

            <div className="rounded border overflow-hidden shadow-md hover:shadow-2xl md:m-2 mb-4">
              <div className="flex flex-col min-h-full">
                <div className="px-6 py-4 border-b">
                  <div className="text-red-600 text-xl text-center">
                    Spring Session Couchbase
                  </div>
                </div>
                <div className="px-6 py-4 flex-grow">
                  <p className="text-gray-700 text-base font-light">
                    A transparent framework which allows you to store the HttpSession of your Java application in Couchbase
                  </p>
                </div>
                <div className="px-6 pb-4 flex justify-center">
                  <a target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#fff' }}
                    className="btn-gradient-default hover:bg-red-700 bg-red-600 visited:text-red-600 text-white font-norma px-4 py-2 mb-2 rounded hover:no-underline"
                    href="https://blog.couchbase.com/sticky-sessions/">Read More</a>
                </div>
              </div>
            </div>

            <div className="rounded border overflow-hidden shadow-md hover:shadow-2xl md:m-2 mb-4">
              <div className="flex flex-col min-h-full">
                <div className="px-6 py-4 border-b">
                  <div className="text-red-600 text-xl text-center">TestContainers</div>
                </div>
                <div className="px-6 py-4 flex-grow">
                  <p className="text-gray-700 text-base font-light">
                    <strong>Testcontainers</strong> is a Java library that supports JUnit tests, providing lightweight,
                    throwaway instances of common databases, Selenium web browsers, or anything else that can run in a Docker container
                  </p>
                </div>
                <div className="px-6 pb-4 flex justify-center">
                  <a target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#fff' }}
                    className="btn-gradient-default hover:bg-red-700 bg-red-600 visited:text-red-600 text-white font-norma px-4 py-2 mb-2 rounded hover:no-underline"
                    href="https://www.testcontainers.org/modules/databases/couchbase/">Read More</a>
                </div>
              </div>
            </div>

            <div className="rounded border overflow-hidden shadow-md hover:shadow-2xl md:m-2 mb-4">
              <div className="flex flex-col min-h-full">
                <div className="px-6 py-4 border-b">
                  <div className="text-red-600 text-xl text-center">JHipster</div>
                </div>
                <div className="px-6 py-4 flex-grow">
                  <p className="text-gray-700 text-base font-light">
                    <strong>JHipster</strong> is a development platform to quickly generate, develop, & deploy modern web applications & microservice architectures
                  </p>
                </div>
                <div className="px-6 pb-4 flex justify-center">
                  <a target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#fff' }}
                    className="btn-gradient-default hover:bg-red-700 bg-red-600 visited:text-red-600 text-white font-norma px-4 py-2 mb-2 rounded hover:no-underline"
                    href="https://www.jhipster.tech/using-couchbase/">Read More</a>
                </div>
              </div>
            </div>

            <div className="rounded border overflow-hidden shadow-md hover:shadow-2xl md:m-2 mb-4">
              <div className="flex flex-col min-h-full">
                <div className="px-6 py-4 border-b">
                  <div className="text-red-600 text-xl text-center">Multi-Cluster Aware with Spring Data SDK</div>
                </div>
                <div className="px-6 py-4 flex-grow">
                  <p className="text-gray-700 text-base font-light">
                    This project shows you how to implement <a className="blue-link" href="https://blog.couchbase.com/couchbase-high-availability-disaster-recovery-java-multi-cluster-aware-client/">Multi-Cluster Awareness</a> inside your Spring project
                  </p>
                </div>
                <div className="px-6 pb-4 flex justify-center">
                  <a target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#fff' }}
                    className="btn-gradient-default hover:bg-red-700 bg-red-600 visited:text-red-600 text-white font-norma px-4 py-2 mb-2 rounded hover:no-underline"
                    href="https://github.com/couchbaselabs/mca-integration-demo">Read More</a>
                </div>
              </div>
            </div>

            <div className="rounded border overflow-hidden shadow-md hover:shadow-2xl md:m-2 mb-4">
              <div className="flex flex-col min-h-full">
                <div className="px-6 py-4 border-b">
                  <div className="text-red-600 text-xl text-center">
                    Couchmove
                  </div>
                </div>
                <div className="px-6 py-4 flex-grow">
                  <p className="text-gray-700 text-base font-light">
                    Couchmove can help you track, manage and apply changes in your Couchbase buckets. The concept is very similar to other database migration tools such as Liquibase, Flyway, mongeez and mongobee
                  </p>
                </div>
                <div className="px-6 pb-4 flex justify-center">
                  <a target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#fff' }}
                    className="btn-gradient-default hover:bg-red-700 bg-red-600 visited:text-red-600 text-white font-norma px-4 py-2 mb-2 rounded hover:no-underline"
                    href="https://github.com/differentway/couchmove">Read More</a>
                </div>
              </div>
            </div>

            <div className="rounded border overflow-hidden shadow-md hover:shadow-2xl md:m-2 mb-4">
              <div className="flex flex-col min-h-full">
                <div className="px-6 py-4 border-b">
                  <div className="text-red-600 text-xl text-center">
                    Field-Level Encryption for the Java SDK
                  </div>
                </div>
                <div className="px-6 py-4 flex-grow">
                  <p className="text-gray-700 text-base font-light">
                    This library adds support for Field-Level Encryption (FLE) to the Couchbase Java SDK.
                    It includes cryptographic algorithms and keyrings you can use out of the box, and provides a framework for implementing your own crypto components
                  </p>
                </div>
                <div className="px-6 pb-4 flex justify-center">
                  <a target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#fff' }}
                    className="btn-gradient-default hover:bg-red-700 bg-red-600 visited:text-red-600 text-white font-norma px-4 py-2 mb-2 rounded hover:no-underline"
                    href="https://github.com/couchbase/java-couchbase-encryption">Read More</a>
                </div>
              </div>
            </div>

            <div className="rounded border overflow-hidden shadow-md hover:shadow-2xl md:m-2 mb-4">
              <div className="flex flex-col min-h-full">
                <div className="px-6 py-4 border-b">
                  <div className="text-red-600 text-xl text-center">
                    User Profile Store
                  </div>
                </div>
                <div className="px-6 py-4 flex-grow">
                  <p className="text-gray-700 text-base font-light">
                    This tutorial shows how you can build a User Profile microservice which Couchbase
                  </p>
                </div>
                <div className="px-6 pb-4 flex justify-center">
                  <a target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#fff' }}
                    className="btn-gradient-default hover:bg-red-700 bg-red-600 visited:text-red-600 text-white font-norma px-4 py-2 mb-2 rounded hover:no-underline"
                    href="https://docs.couchbase.com/tutorials/profile-store/java.html">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="bg-gray-800 rounded md:mx-2 pb-3 pt-5 md:px-5 px-4">
            <h2 className="text-white text-2xl mb-4 lg:mb-3 lg:ml-3 ml-1">Recommended Videos</h2>
            <div className="main-images lg:mb-8 px-0 md:px-4 ">
              <div className="images grid grid-cols-1 md:grid-cols-3 md:gap-8 gap-4">
                <div className="image bg-white rounded-lg shadow-lg overflow-hidden mb-3 md:mb-5 border border-gray-700">
                  <a target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.youtube.com/watch?v=B9qRJhA1ONs">
                    <img src={shotJavaFtsDevoxx} alt="Full-Text Search Tips & Tricks" title="Full-Text Search Tips & Tricks" />
                    <span className="text-center p-2 text-gray-700 text-sm inline-block w-full">Devoxx France | Full-Text Search Tips & Tricks</span>
                  </a>
                </div>
                <div className="image bg-white rounded-lg shadow-lg overflow-hidden mb-3 md:mb-5 border border-gray-700">
                  <a target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.youtube.com/watch?v=MpqxIkFjbew&list=PLcspbWiU9RuvuKfAs0D3wbgRi3Nlu7Ldu">
                    <img src={shotJavaFtsTraining} alt="Introduction to the Java SDK" title="Introduction to the Java SDK" />
                    <span className="text-center p-2 text-gray-700 text-sm inline-block w-full">Training | Introduction to the Java SDK</span>
                  </a>
                </div>
                <div className="image bg-white rounded-lg shadow-lg overflow-hidden mb-3 md:mb-5 border border-gray-700">
                  <a target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.youtube.com/watch?v=KyzXc3MxqZk" >
                    <img src={shotJavaReactive} alt="Reactive Web applications with Spring WebFlux & Couchbase " title="Reactive Web applications with Spring WebFlux & Couchbase " />
                    <span className="text-center p-2 text-gray-700 text-sm inline-block w-full">Reactive Web applications with Spring WebFlux & Couchbase</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )


}

export default Java